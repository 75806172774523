/* Mixins */
.device-type-detail-component[data-v-6a46e37d]  .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 120px;
  padding: 16px;
}
.device-type-detail-component[data-v-6a46e37d]  .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px;
}
.device-type-detail-component .mediaWidth[data-v-6a46e37d] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.device-type-detail-component .mediaWidth[data-v-6a46e37d] {
    max-width: 380px;
}
}
@media only screen and (max-width: 1500px) {
.device-type-detail-component .mediaWidth[data-v-6a46e37d] {
    max-width: 170px;
}
}