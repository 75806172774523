/* Mixins */
.driver-config-component .form-title[data-v-a4b9d67e] {
  font-weight: bold;
  font-size: 14px;
  padding-left: 10%;
  color: #575757;
}
.form-item-message[data-v-a4b9d67e] {
  position: absolute;
  padding-left: 10px;
  color: #575757;
  word-break: break-all;
  width: 50%;
}
.enum-remove-link[data-v-a4b9d67e] {
  position: absolute;
  width: 36px;
  margin-left: 8px;
}